
import { Options, Vue } from 'vue-class-component';
import LoginLayout from '@/lib/layouts/LoginLayout.vue';
import { BaseButton, BaseTextInput } from '@/lib/components';
import { UserService } from '@/services/api';
import BaseAlert from '@/lib/components/Banner/BaseAlert.vue';
import { TokenService } from '@/services';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    BaseAlert,
    LoginLayout,
    BaseTextInput,
    BaseButton
  }
})
export default class NewPasswordPage extends Vue {
  protected isPasswordSet = false;
  protected password = '';
  protected passwordConfirmation = '';
  protected successMessage = '';
  protected errorMessage: { [key: string]: string[] } = {};
  protected loading = false;
  protected unableToReset = false;

  userService = new UserService();
  notificationStore = useNotificationStore();

  get token(): string {
    const token = this.$route.query.token;

    return typeof token === 'string' ? token : '';
  }

  mounted() {
    TokenService.removeToken();
  }

  async sendInvite() {
    await this.userService.sendInvite({ token: this.token });
    this.notificationStore.snackbar = {
      label: this.$t('platform.new-password.invite-resent-successfully'),
      color: 'success'
    };
  }

  async setPassword() {
    try {
      this.loading = true;
      this.errorMessage = {};

      await this.userService.setPasswordAndActivate({
        password: this.password,
        password_confirmation: this.passwordConfirmation,
        token: this.token
      });

      this.isPasswordSet = true;
    } catch (error) {
      if (error.response?.status === 400) {
        if (error.response.data.message === 'User is already activated.') {
          this.notificationStore.snackbar = {
            label: this.$t('platform.new-password.already-activated'),
            icon: null,
            autoDismiss: false
          };

          await this.$router.push({ name: 'login' });
          return;
        }

        this.unableToReset = true;
        return;
      }

      if (error.response?.status === 403) {
        this.errorMessage = { password: [error.response.data.message] };
        return;
      }

      this.errorMessage = error.response?.data?.errors || {};
    } finally {
      this.loading = false;
    }
  }

  async backToLogin() {
    await this.$router.push({ name: 'login' });
  }
}
